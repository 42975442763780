export default `
<div><p style="margin-top:0pt; margin-bottom:12pt; widows:0; orphans:0; font-size:16pt"><span style="font-family:Arial-BoldMT; font-weight:bold; color:#313131">e-GroWARUNKI KORZYSTANIA Z APLIKACJI </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:10.5pt"><span style="font-family:ArialMT; color:#1a1718">Niniejszy
 dokument (wraz z wymienionymi w nim dokumentami) zawiera warunki 
korzystania z aplikacji e-Gro firmy Grodan. Przed rozpocz</span><span style="font-family:ArialMT; color:#1a1718">ęciem
 korzystania z aplikacji należy dokładnie zapoznać się z niniejszymi 
warunkami korzystania z oprogramowania. Korzystając z Aplikacji, 
Użytkownik zgadza się przestrzegać niniejszych Warunków korzystania. 
Należy zachować kopię niniejszych Warunków korzystania, aby móc się 
do nich odnieść w przyszłości. Ostatnia aktualizacja: 29 czerwca, 
2018 r. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">1 Definicje </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">1.1 W niniejszym dokumencie terminy „Grodan”, „my” i „nas oznaczaj</span><span style="font-family:ArialMT">ą
 firmę Rockwool BV, działająca pod nazwą Grodan, spółkę prawa 
holenderskiego zarejestrowaną w rejestrze handlowym pod numerem 
13014428, z siedzibą pod adresem Industrieweg 15, 6045 JG Roermond, 
Holandia; termin „Warunki korzystania” oznacza niniejsze Warunki 
korzystania z aplikacji e-Gro, które określają warunki korzystania z 
aplikacji; termin „Aplikacja” oznacza aplikację e-Gro; termin Usługi 
oznacza usługę lub usługi oferowane lub dostarczane przez firmę Grodan
 za pośrednictwem aplikacji; a termin „Użytkownik” oznacza osobę 
fizyczną lub osobę prawną korzystającą z Aplikacji. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">2 Zastosowanie </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">2.1 Niniejsze Warunki korzystania reguluj</span><span style="font-family:ArialMT">ą
 korzystanie z Aplikacji i wykluczają wszelkie inne ogólne warunki, z 
których korzysta Użytkownik. Inne warunki mają zastosowanie 
wyłącznie, gdy zostały wyraźnie zaakceptowane przez firmę Grodan w 
osobnej indywidualnej umowie.2.2 Korzystając z Aplikacji (co obejmuje 
również pobieranie i instalowanie Aplikacji), Użytkownik akceptuje 
niniejsze Warunki korzystania. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">2.3 Je</span><span style="font-family:ArialMT">żeli
 Aplikacja korzysta z usług osób trzecich, mogą mieć zastosowanie 
wszelkie warunki i/lub polityki prywatności tych stron trzecich. Firma 
Grodan nie ponosi odpowiedzialności za usługi i/lub politykę 
prywatności stron trzecich.2.4 Od czasu do czasu mamy prawo do zmiany i
 modyfikacji niniejszych Warunków korzysta </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">3 Status U</span><span style="font-family:Arial-BoldMT; font-weight:bold">żytkownika </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">Korzystaj</span><span style="font-family:ArialMT">ąc
 z Aplikacji, Użytkownik gwarantuje, że:a) ma co najmniej 18 lat; 
ib) (dotyczy osób fizycznych) posiada zdolność prawną do zawierania 
wiążących umów; orazc) jeżeli Użytkownik korzysta z Aplikacji w 
imieniu osoby prawnej, jest upoważniony do akceptowania niniejszych 
Warunków korzystania w imieniu tej osoby prawnej. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">4 Licencja </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">4.1 Firma Grodan niniejszym udziela U</span><span style="font-family:ArialMT">żytkownikowi
 niewyłącznej, niepodlegającej licencjonowaniu i niezbywalnej licencji
 na korzystanie z Aplikacji.4.2 Bez uprzedniej pisemnej zgody firmy 
Grodan Użytkownikowi nie wolno udostępniać Aplikacji osobom trzecim 
(z wyjątkiem konsultantów Użytkownika), sprzedać ani wypożyczać 
Aplikacji osobom trzecim, dekompilować, dokonywać inżynierii 
wstecznej lub modyfikować Aplikacji ani (pozwalać innym osobom) 
usuwać lub obchodzić techniczne zabezpieczenia mające chronić 
Aplikację. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">4.3 U</span><span style="font-family:ArialMT">żytkownik
 może udzielać swoim pracownikom dostępu do Aplikacji pod warunkiem, 
że dostęp ten jest zawsze udzielany pod nadzorem Użytkownika. 
Wszelkie dane osobowe przesłane do Aplikacji są uważane za dane 
Użytkownika. 4.4 Firma Grodan może w dowolnym momencie (i) zaprzestać
 udostępniania Aplikacji, tymczasowo lub na stałe; (ii) wprowadzić 
proceduralne i/lub techniczne zmiany i/lub ulepszenia w Aplikacji; (iii)
 zmienić lub usunąć dane bądź informacje; (iv) ograniczyć 
korzystanie z Aplikacji lub niektórych funkcji; i/lub (v) uniemożliwić
 Użytkownikowi dostęp do i/ lub korzystanie z Aplikacji w całości lub
 w części, czasowo lub na stałe i/lub poprzez wypowiedzenie licencji. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">4.5 Firma Grodan ma prawo do indeksowania swoich cen raz w roku.</span><span style="font-family:ArialMT">4.6
 Oprócz praw przysługujących na mocy punktu 4.5 firmie Grodan 
przysługuje prawo do korekty cen w przypadku zmiany czynników mających 
wpływ na wielkość kosztów, w tym podatków, podatków akcyzowych, 
należności celnych przywozowych, kursów walut, wynagrodzeń, cen 
towarów i/lub usług (niezależnie od tego, czy firma Grodan uzyskała je 
od stron trzecich), które to zmiany nastąpiły po złożeniu oferty i/lub
 zawarciu umowy. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">5 Korzystanie z Aplikacji </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">5.1 U</span><span style="font-family:ArialMT">żytkownik
 korzysta z Aplikacji za każdym razem wyłącznie na własne ryzyko i 
odpowiedzialność.5.2 Aplikacji można używać wyłącznie do celów 
zgodnych z prawem. Użytkownik nie może używać Aplikacji (i) w sposób
 naruszający obowiązujące lokalne, krajowe lub międzynarodowe 
przepisy prawa lub regulacje; lub (ii) w jakikolwiek inny sposób, który 
jest niezgodny z prawem, stanowi nadużycie, lub ma bezprawny lub 
nieuczciwy cel lub skutek.5.3 Po pobraniu przez Użytkownika Aplikacji,
 Użytkownik może się zarejestrować za pośrednictwem Aplikacji. 
Firma Grodan przyjmuje wyłącznie wnioski rejestracyjne całkowicie 
uzupełnione prawdziwymi informacjami. W przypadku jakichkolwiek zmian 
danych rejestracyjnych lub innych istotnych danych Użytkownik 
aktualizuje takie dane za pomocą Aplikacji.5.4 Jeżeli Użytkownik 
wybierze lub otrzyma kod identyfikacyjny, hasło lub jakąkolwiek inną 
informację w ramach naszych procedur bezpieczeństwa, musi traktować 
te informacje jako dane poufne i nie ujawniać ich żadnej stronie 
trzeciej. Mamy prawo do wyłączenia w dowolnym momencie dowolnego kodu 
identyfikacyjnego lub hasła użytkownika, niezależnie od tego, czy 
zostały wybrane przez Użytkownika czy przez nas przydzielone, jeżeli 
uznamy, że Użytkownik nie przestrzega niniejszych Warunków 
korzystania.5.5 Aby móc korzystać z Aplikacji, Użytkownik powinien na
 własny koszt zapewnić połączenie niezbędne do uzyskania dostępu do 
Aplikacji. Połączenie obejmuje m.in. niezbędny sprzęt, oprogramowanie
 systemowe i funkcje łączności (Internet). Firma Grodan nie ponosi 
odpowiedzialności za błędy połączenia leżące po stronie dostawców 
usług łączności klientów.5.6 Jeżeli Użytkownik dostarcza 
informacje, dane i/lub inne treści („Wkład”) za pośrednictwem 
Aplikacji, Użytkownik gwarantuje, że jest prawowitym właścicielem 
Wkładu oraz że Wkład nie zawiera żadnych informacji niezgodnych z 
prawem. Pełne działanie Aplikacji jest możliwe wyłącznie po przesłaniu
 Wkładu, który jest kompletny, poprawny i aktualny. 5.7 Użytkownik 
niniejszym udziela firmie Grodan niewyłącznej, bezterminowej, 
nieodpłatnej, nie podlegającej licencjonowaniu i przeniesieniu licencji
 na używanie Wkładu i przyjmuje do wiadomości, że firma Grodan ma 
prawo do wykorzystywania i udostępniania zbiorczych informacji 
związanych z działalnością biznesową, w tym Wkładów, nie wskazując 
konkretnie Użytkownika ani osoby prawnej, w imieniu której Użytkownik 
korzysta z Aplikacji (i) w celu umożliwienia Użytkownikowi korzystania
 z Aplikacji; (ii) w celu zapewnienia i poprawy jakości usług firmy 
Grodan poprzez zastosowanie Aplikacji; i/lub (iii) w celu aktualizacji 
i/lub dalszego rozwoju Aplikacji.5.8 Użytkownik uznaje i potwierdza, 
że Wkład może być przekazywany i wykorzystywany przez osoby trzecie 
zaangażowane przez firmę Grodan, w tym dostawcę chmury firmy Grodan, w
 celu obsługi i/lub zarządzania Aplikacją.5.9 Firma Grodan określi, 
według własnego uznania, czy doszło do naruszenia niniejszych Warunków 
korzystania. Jeżeli doszło do naruszenia niniejszych Warunków 
korzystania, możemy podjąć wszelkie działania, które uznamy za 
stosowne.5.10 Używanie Aplikacji jest dozwolone wyłącznie w 
przypadku, gdy Użytkownik spełnił i nadal spełnia swoje zobowiązania 
dotyczące płatności na rzecz firmy Grodan. Jeżeli płatność nie 
wpłynie terminowo, zostaną naliczone odsetki w wysokości 1% 
miesięcznie od kwoty pozostałej do zapłaty, liczonej od dnia 
wymagalności do dnia płatności włącznie, przy czym część miesiąca
 jest traktowana jako pełny miesiąc. Użytkownik jest zobowiązany do 
zapłaty kwoty odsetek podanej na fakturze bez potrzeby wysyłania 
dalszych ponagleń. Wszelkie koszty związane z windykacją należności
 będą ponoszone przez Użytkownika. Pozasądowe koszty windykacji 
należności wynoszą co najmniej 15% kwoty należności, przy czym 
minimalna kwota wynosi 200 EUR.5.11 Zapewniając Użytkownikowi dostęp
 do Aplikacji, firma Grodan w żaden sposób nie pomaga Użytkownikowi w 
łamaniu jakichkolwiek przepisów ustawowych lub wykonawczych 
obowiązujących w państwie lub kraju zamieszkania Użytkownika </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">6 Prywatno</span><span style="font-family:Arial-BoldMT; font-weight:bold">ść </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">6.1 Mo</span><span style="font-family:ArialMT">żemy
 wykorzystywać dane osobowe Użytkownika, na przykład informacje 
dostarczone przez Użytkownika za pośrednictwem Aplikacji, poprzez 
korzystanie z Aplikacji lub w inny sposób, informacje o korzystaniu 
przez Użytkownika z Aplikacji (pliki dziennika) oraz dane (techniczne) 
urządzenia przenośnego Użytkownika. Takie dane osobowe mogą być 
gromadzone i wykorzystywane w celu zapewnienia prawidłowego działania 
Aplikacji, świadczenia Usług na rzecz Użytkownika, usprawnienia 
Aplikacji i/lub Usług, usprawnienia Aplikacji przy wykorzystaniu uczenia
 maszynowego oraz kontaktowania się z Użytkownikiem lub dostarczania 
mu istotnych informacji dotyczących (korzystania z) Aplikacji i/lub 
Usług. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">6.2 W zakresie, w jakim przetwarzamy dane osobowe U</span><span style="font-family:ArialMT">żytkownika,
 dane te będą traktowane i przetwarzane zgodnie z obowiązującymi 
przepisami dotyczącymi ochrony danych osobowych. Korzystając z 
Aplikacji i przekazując nam wszelkie dane osobowe za jej 
pośrednictwem, Użytkownik wyraża zgodę na przetwarzanie takich 
danych zgodnie z niniejszymi Warunkami korzystania i obowiązującymi 
przepisami dotyczącymi ochrony danych osobowych. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">6.3 Wszelkie dane osobowe dostarczone przez U</span><span style="font-family:ArialMT">żytkownika
 za pośrednictwem Aplikacji będą wykorzystywane, wraz z danymi 
osobowymi innych użytkowników, na potrzeby ulepszania Aplikacji. 
Wszelkie dane wykorzystywane w zakresie niniejszego punktu 6.3 będą 
anonimizowane, a zatem nie mogą być uważane za dane osobowe na mocy 
przepisów o ochronie danych osobowych. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">6.4 Dane osobowe b</span><span style="font-family:ArialMT">ędą
 przesyłane do krajów spoza UE w celu: świadczenia Usług i 
udostępniania Aplikacji. Korzystając z Aplikacji i przekazując nam 
wszelkie dane osobowe za jej pośrednictwem, Użytkownik wyraża 
również zgodę na przetwarzanie takich danych zgodnie z niniejszymi 
Warunkami korzystania i obowiązującymi przepisami dotyczącymi ochrony
 danych osobowych. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">6.5 Aby dowiedzie</span><span style="font-family:ArialMT">ć
 się więcej o polityce prywatności firmy Grodan, należy zapoznać 
się z naszym oświadczeniem dotyczącym prywatności, które można 
znaleźć również na naszej stronie internetowej. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">7 Odpowiedzialno</span><span style="font-family:Arial-BoldMT; font-weight:bold">ść </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">7.1 W miar</span><span style="font-family:ArialMT">ę
 naszych możliwości dołożymy wszelkich starań, aby udostępniać 
Aplikację z należytą starannością. Firma Grodan nie może jednak 
zagwarantować, że Aplikacja będzie zawsze dostępna lub będzie 
działać bez przerw, błędów bądź usterek, lub że informacje zawarte w
 Aplikacji bądź dostarczone przez firmę Grodan za pośrednictwem 
Aplikacji („Informacje”) są kompletne, poprawne i/lub aktualne. Nie 
ponosimy odpowiedzialności wobec Użytkownika ani żadnej innej strony,
 jeżeli z jakiegokolwiek powodu Aplikacja będzie niedostępna w 
dowolnym czasie lub okresie. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">7.2 Wszystkie informacje s</span><span style="font-family:ArialMT">ą
 dostarczane przez firmę Grodan w sposób niezobowiązujący. Firma 
Grodan nie składa żadnych oświadczeń ani nie udziela żadnych 
gwarancji, wyraźnych ani dorozumianych, w odniesieniu do kompletności,
 dokładności, niezawodności lub dostępności Informacji. Użytkownik 
polega na Informacjach wyłącznie na własne ryzyko. 7.3 Firma Grodan nie
 ponosi odpowiedzialności za Wkłady, informacje i inne materiały lub 
komunikaty, w tym za ich dokładność, które Użytkownik lub inne osoby 
publikują bądź przesyłają za pośrednictwem Aplikacji. Firma Grodan 
zastrzega sobie prawo do (zapowiadanego lub niezapowiadanego) usunięcia
 wszelkich Wkładów, informacji lub innych materiałów bądź 
oświadczeń, które zostały złożone za pośrednictwem Aplikacji. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">7.4 W najszerszym zakresie dozwolonym na mocy obowi</span><span style="font-family:ArialMT">ązującego
 prawa firma Grodan niniejszym zrzeka się wszelkiej odpowiedzialności i
 w żadnym wypadku Grodan nie będzie odpowiedzialna za jakiekolwiek 
szkody, w tym, między innymi, szkody bezpośrednie, pośrednie lub 
wtórne, w tym utratę dochodów, utratę zysków, utratę korzyści lub 
inne straty wynikające z korzystania lub niemożności korzystania z 
Aplikacji, w tym szkody wynikające z niedokładności, porad, 
ostrzeżeń, powiadomień, przeoczeń lub błędów w Aplikacji i/lub 
Informacjach. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">7.5 Firma Grodan nie ponosi odpowiedzialno</span><span style="font-family:ArialMT">ści
 za jakąkolwiek niemożność korzystania z Aplikacji spowodowaną 
zdarzeniami będącymi poza jej bezpośrednią kontrolą („siła 
wyższa”). Do przykładów siły wyższej zaliczają się wszelkie 
działania, zdarzenia, zaniedbania, pominięcia lub wypadki poza naszą 
kontrolą, co obejmuje w szczególności (między innymi): (i) 
niemożność korzystania z publicznych lub prywatnych sieci 
telekomunikacyjnych lub elektroenergetycznych; (ii) regulacje prawne lub
 ograniczenia (eksportowe) jakiegokolwiek organu administracji 
publicznej; (iii) cyberterroryzm lub inne cyberataki, incydenty 
bezpieczeństwa, umyślne lub w inny sposób powodujące uszkodzenie lub 
utratę danych oraz (iv) niedoręczenie lub opóźnione dostarczenie 
produktów bądź usług firmy Grodan przez strony trzecie zaangażowane 
przez firmę Grodan. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">7.6 Je</span><span style="font-family:ArialMT">żeli
 firma Grodan poniesie jakiekolwiek straty, w tym m.in. z powodu sankcji
 władz i roszczeń osób trzecich, w związku z jakimkolwiek naruszeniem 
punktu 10 niniejszych Warunków korzystania i/lub Kodeksu postępowania 
firmy Grodan przez Użytkownika, Użytkownik powinien w pełni 
zabezpieczyć firmę Grodan przed poniesieniem takich strat. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">8 Prawa w</span><span style="font-family:Arial-BoldMT; font-weight:bold">łasności intelektualnej </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">8.1 U</span><span style="font-family:ArialMT">żytkownik
 uznaje i przyjmuje do wiadomości, że wszelkie prawa własności 
intelektualnej i/lub pokrewne prawa do (treści i projektu) Aplikacji, w
 tym do obrazów bazowych, filmów i plików dźwiękowych, jak również 
Informacje są własnością tylko i wyłącznie firmy Grodan i/lub jej 
licencjodawców.8.2 Użytkownik zapewni, że korzystanie przez niego z 
Aplikacji w żaden sposób nie naruszy praw i/lub dobrego imienia ani 
reputacji firmy Grodan. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">9 Poufno</span><span style="font-family:Arial-BoldMT; font-weight:bold">ść </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">9.1 Przetwarzanie informacji, oblicze</span><span style="font-family:ArialMT">ń,
 porad i innych wyników odbywa się na podstawie informacji 
dostarczonych przez Użytkownika. Rezultat przetwarzania informacji, 
obliczeń, porad i innych wyników jest ściśle poufny. W żadnym 
wypadku nie wolno ujawniać całości ani części metody, rezultatów 
obliczeń, porad i innych wyników, zezwalać na ich sprawdzanie przez 
osoby trzecie, publikować ich lub wyrażać zgody na ich publikację 
bez pisemnej zgody firmy Grodan. 9.2 W przypadku naruszenia 
zobowiązania określonego w punkcie 9.1 Użytkownik zostanie 
niezwłocznie obciążony karą w wysokości 2,500 EUR za każde 
naruszenie i 1000 EUR za każdy dzień trwania naruszenia, bez 
uszczerbku dla prawa firmy Grodan do odszkodowania za straty spowodowane
 naruszeniem i bez uszczerbku dla prawa do dochodzenia wypełnienia 
niniejszych Warunków korzystania. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">10 Zgodno</span><span style="font-family:Arial-BoldMT; font-weight:bold">ść z przepisami </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">10.1 U</span><span style="font-family:ArialMT">żytkownik
 oświadcza i gwarantuje, że będzie korzystał z licencji, Aplikacji i 
Usług świadczonych przez firmę Grodan do celów, dla których firma 
Grodan dostarczyła licencję, Aplikację i Usługi Użytkownikowi. 
Ponadto Użytkownik oświadcza i gwarantuje, że wszystkie jego 
działania są zgodne ze wszystkimi obowiązującymi przepisami i 
regulacjami. W żadnym wypadku Użytkownik nie może wykorzystywać 
licencji, Aplikacji i Usług świadczonych przez firmę Grodan w celu 
prowadzenia nielegalnej działalności.10.2 Na pierwsze żądanie firmy 
Grodan Użytkownik podejmie wymaganą współpracę, udostępni obiekty i 
przekaże informacje na potrzeby inspekcji i/lub kontroli przez firmę 
Grodan lub stronę trzecią zaangażowaną przez firmę Grodan, aby się
 upewnić się, że Użytkownik działa w pełnej zgodności z zapisami 
punktu 10, Kodeksu postępowania klientów firmy Grodan oraz 
obowiązujących krajowych przepisów i regulacji.10.3 W przypadku 
jakiegokolwiek naruszenia zapisów niniejszego punktu 10 firma Grodan ma 
prawo do natychmiastowego zerwania stosunków handlowych z 
Użytkownikiem, w tym wszelkich umów, które mogły zostać zawarte, oraz 
uniemożliwić mu korzystanie z aplikacji e-Gro. Firma Grodan ma 
również prawo do anulowania każdego złożonego zamówienia, nawet 
jeśli zostało ono wyraźnie przyjęte przez firmę Grodan. Użytkownik 
nie będzie uprawniony do otrzymania odszkodowania od firmy Grodan w 
przypadku rozwiązania umowy na mocy niniejszego punktu 10.3. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">11 Powiadomienia </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">11.1 Wszelkie powiadomienia wysy</span><span style="font-family:ArialMT">łane
 przez Użytkownika do firmy Grodan należy przesyłać pocztą 
elektroniczną na adres info@grodan.com lub listem poleconym bądź 
pismem sądowym do firmy Rockwool BV działającej pod nazwą Grodan, na 
adres: Industrieweg 15, 6045 JG Roermond, Holandia.11.2 Możemy 
wysyłać powiadomienia do Użytkownika na adres e-mail lub adres 
pocztowy podany podczas rejestracji za pośrednictwem Aplikacji. 
Powiadomienie zostanie uznane za otrzymane i prawidłowo dostarczone po 
upływie 24 godzin (dzień roboczy) od wysłania wiadomości e-mail, 
niezwłocznie po doręczeniu pisma sądowego lub po trzech dniach 
roboczych od daty nadania jakiegokolwiek listu. Aby dowieść 
doręczenia jakiegokolwiek powiadomienia, wystarczy przedstawić 
potwierdzenie odbioru listu poleconego lub kopii pisma sądowego oraz, w
 przypadku wiadomości e-mail, wysłanie takiej wiadomości e-mail na 
wskazany adres e-mail adresata. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">12 Przeniesienie praw i obowi</span><span style="font-family:Arial-BoldMT; font-weight:bold">ązków1</span><span style="font-family:ArialMT">2.1 U</span><span style="font-family:ArialMT">żytkownik
 nie może przenosić, cedować, przepisywać, podzlecać ani w inny 
sposób zbywać swoich praw lub zobowiązań wynikających z niniejszych 
Warunków korzystania, bez uprzedniej pisemnej zgody firmy Grodan.12.2 W
 dowolnym momencie możemy przenosić, cedować, przepisywać, 
podzlecać lub w inny sposób rozporządzać naszymi prawami lub 
obowiązkami wynikającymi z niniejszych Warunków korzystania. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">13 Zrzeczenie si</span><span style="font-family:Arial-BoldMT; font-weight:bold">ę praw </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">13.1 Je</span><span style="font-family:ArialMT">śli
 nie będziemy kategorycznie nalegać na wykonanie któregokolwiek z 
obowiązków Użytkownika wynikających z niniejszych Warunków 
korzystania lub jeśli nie skorzystamy z któregokolwiek z praw lub 
środków, które nam przysługują zgodnie z niniejszymi Warunkami 
korzystania, nie będzie to stanowić zrzeczenia się takich praw lub 
środków i nie zwalnia Użytkownika z przestrzegania takich 
zobowiązań. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">13.2 Zrzeczenie si</span><span style="font-family:ArialMT">ę
 prawa do egzekwowania wywiązania się z obowiązków nie będzie 
stanowić zrzeczenia się z naszej strony prawa do egzekwowania 
późniejszego wywiązania się z tychże obowiązków.13.3 Żadne 
zrzeczenie się przez nas któregokolwiek z praw przysługujących nam na 
mocy niniejszych Warunków korzystania nie będzie skuteczne, o ile nie 
zostanie wyraźnie określone jako zrzeczenie się i przekazane na 
piśmie zgodnie z punktem 10. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">14 Rozdzielno</span><span style="font-family:Arial-BoldMT; font-weight:bold">ść postanowień </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">14.1 Je</span><span style="font-family:ArialMT">żeli
 którykolwiek z niniejszych Warunków korzystania zostanie uznany przez 
jakikolwiek właściwy organ za nieważny, niezgodny z prawem lub 
niewykonalny w jakimkolwiek zakresie, warunek ten zostanie w tym 
zakresie oddzielony od pozostałych warunków, które będą nadal ważne w
 najszerszym zakresie dozwolonym na mocy obowiązującego prawa. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:Arial-BoldMT; font-weight:bold">15 Prawo i jurysdykcja </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">15.1 Niniejsze Warunki korzystania b</span><span style="font-family:ArialMT">ędą
 regulowane i interpretowane zgodnie z prawem holenderskim, chyba że 
zastosowanie mają obowiązujące przepisy prawa lokalnego.15.2 
Wszelkie spory wynikające bezpośrednio lub pośrednio z niniejszych 
Warunków korzystania podlegają wyłącznej jurysdykcji sądów 
holenderskich. Odstępując od tego przepisu, firma Grodan będzie 
również miała prawo do wniesienia powództwa w sprawie sporu lub 
roszczenia do sądu właściwego dla miejsca, w którym Użytkownik ma 
miejsce zamieszkania lub siedzibę bądź rzeczywiste miejsce 
prowadzenia działalności. Niniejszy punkt nie ma wpływu na prawa 
ustawowe Użytkownika. </span></p><p style="margin-top:12pt; margin-bottom:12pt; widows:0; orphans:0; font-size:8pt"><span style="font-family:ArialMT">Wersja, June 2018 </span></p></div>
`
